import { HttpClientService, TranslationService } from '@amaris/lib-highway/services';
import { Injectable } from '@angular/core';
import {
  Observable, catchError, map, of,
} from 'rxjs';
import { HttpOptionsModel } from '@amaris/lib-highway/models';
import { ToastrService } from 'ngx-toastr';
import { ApiResponse } from '../../../models/api-response.model';
import { MediacalPractice } from '../../../models/medical-practice.model';
import { environment } from '../../../../environments/environment';
import { ApiEndpoints } from '../../../enums/api-endpoints.enum';
import { DataStoreService } from '../../data-store/data-store.service';
import { Dropdown } from '../../../models/dropdown.model';

@Injectable({
  providedIn: 'root',
})
export class MedicalPracticesService {
  constructor(
    private readonly httpClientService: HttpClientService,
    private readonly dataStoreService: DataStoreService,
    private readonly toastrService: ToastrService,
    private readonly translationService: TranslationService,
  ) { }

  getAll(): Observable<MediacalPractice[]> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.MedicalPractices}`,
      withCredentials: true,
    };
    return this.httpClientService.get<ApiResponse<MediacalPractice[]>>(options).pipe(
      map((response: ApiResponse<MediacalPractice[]>) => {
        this.dataStoreService.setMedicalPractices(response.data);
        this.dataStoreService.setMedicalPracticesDropdown(response.data.map((practice) => new Dropdown(practice.companyName, practice.id)));
        return response.data;
      }),
    );
  }

  getById(id: string): Observable<MediacalPractice> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.MedicalPractices}/${id}`,
      withCredentials: true,
    };
    return this.httpClientService.get<ApiResponse<MediacalPractice>>(options).pipe(
      map((response: ApiResponse<MediacalPractice>) => {
        this.dataStoreService.setCurrentMedicalPractice(response.data);
        return response.data;
      }),
    );
  }

  createMedicalPractice(medicalPractice: MediacalPractice): Observable<string> {
    const options: HttpOptionsModel = {
      url: environment.apiUrl + ApiEndpoints.MedicalPractices,
      body: medicalPractice,
      withCredentials: true,
    };
    return this.httpClientService.post<ApiResponse<string>>(options).pipe(
      map((response) => {
        if (response.success) {
          this.getById(response.data).subscribe();
          this.getAll().subscribe();
          this.toastrService.success('Cabinet créé avec succès');
        } else {
          this.toastrService.error('Une erreur est survenue lors de la création du cabinet');
        }
        return response.data;
      }),
      catchError(() => {
        this.toastrService.error('Une erreur est survenue lors de la création du cabinet');
        return of(null);
      }),
    );
  }

  updateMedicalPractice(medicalPractice: MediacalPractice): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.MedicalPractices}/${medicalPractice.id}`,
      body: medicalPractice,
      withCredentials: true,
    };
    return this.httpClientService.patch<ApiResponse<string>>(options).pipe(
      map((response) => {
        if (response.success) {
          this.getById(response.data).subscribe();
          this.getAll().subscribe();
          this.toastrService.success('Cabinet modifié avec succès');
        } else {
          this.toastrService.error('Une erreur est survenue lors de l\'édition du cabinet');
        }
        return response.data;
      }),
      catchError((error: any) => {
        // if (error?.status === 400) {
        //   this.toastrService.error(this.translationService.getTranslation(`core.${error.error.message}`));
        // }
        this.toastrService.error('Une erreur est survenue lors de l\'édition du cabinet');
        return of(null);
      }),
    );
  }
}
